/* App.css */
.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap'); /* Import Google Font */

body {
  font-family: var(--font-family); /* Use custom font */
  background-color: var(--background-color); /* Set background color */
  color: var(--text-color); /* Set text color */
}
header {
  background-color: var(--primary-color); /* Set header background color */
  color: rgb(0, 0, 0); /* Set header text color */
  padding: 20px;
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}
.editor-preview-container {
  flex: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.editor-container,
.preview-container {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: white; /* Set background color */
}

.markdown-tools {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: white; /* Set background color */
}

.editor-container textarea {
  width: 100%;
  height: 200px;
}

h2.tools-heading {
  margin-top: 0;
  font-size: 1.5rem; /* Increase font size */
  color: #333; /* Change font color */
}

ul {
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 8px; /* Add padding */
  border-bottom: 1px solid #ccc; /* Add bottom border */
}

li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

li:hover {
  background-color: #f0f0f0;
}
